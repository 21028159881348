<template>
  <div class="home">
    <SlideComp />
    <IntroComp />
    <ProductsComp />
  </div>
</template>

<script>
import SlideComp from "@/components/Home/SlideComp";
import IntroComp from "@/components/Home/IntroComp";
import ProductsComp from "@/components/Home/ProductsComp";
export default {
  name: "HomeView",
  components: { SlideComp, IntroComp, ProductsComp },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: rgb(37, 79, 26);
}
</style>
