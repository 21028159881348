<template>
  <div class="slide" :style="style">
    <div>
      <h2>{{ slide_text }}</h2>
      <router-link :to="{ name: 'products' }" class="slide_button"
        >SHOP NOW</router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "SlideComp",
  data() {
    return {
      style: {
        "background-image":
          "url(https://cdn.urunonerisi.com/files/499cfdeff3c2693046bfc7ee6f1f8eba.jpg)",
      },
      slide_text: "",
    };
  },
  mounted() {
    this.$store
      .dispatch("Settings/GET_SETTINGS", "slide_image,slide_text")
      .then((res) => {
        this.slide_text = res.settings.slide_text;
        this.style["background-image"] = `url(${res.settings.slide_image})`;
      })
      .catch(() => {});
  },
};
</script>
<style lang="scss" scoped>
.slide {
  width: 100%;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  div {
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 10rem 0;

    background: rgba(#264f1a, 0.22);

    h2 {
      color: #fff;
      font-size: 40px;
      font-weight: 600;
      font-family: "Link Sans";
      text-align: center;
    }

    a {
      color: white;
      font-size: 15px;
      font-weight: 600;
      background-color: #ffffff00;
      border-style: solid;
      border-width: 1px;
      border-radius: 0;
      padding: 15px 30px;
      text-decoration: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #264f1a;
      }
    }
  }
}
</style>
