import axios from "axios";

export default {
  namespaced: true,
  state: {
    products: [],
  },
  getters: {
    _products(state) {
      return state.products;
    },
  },
  mutations: {
    setProducts(state, payload) {
      state.products = payload;
    },
  },
  actions: {
    GET_PRODUCTS(context) {
      return new Promise((resolve, reject) => {
        axios
          .get("/products")
          .then((res) => {
            context.commit("setProducts", res.data.products);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setProducts", []);
            reject(err?.response?.data || err);
          });
      });
    },
    GET_PRODUCT(context, slug) {
      return new Promise((resolve, reject) => {
        axios
          .get("/products/" + slug)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
