<template>
  <nav class="navbar navbar-expand-lg bg-body-light">
    <div class="container-fluid">
      <router-link :to="{ name: 'home' }" active-class="active"
        ><img src="@/assets/logo.svg" alt=""
      /></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarNavAltMarkup"
      >
        <div class="navbar-nav">
          <router-link
            :to="{ name: 'home' }"
            active-class="active"
            class="nav-link"
          >
            Ana Sayfa
          </router-link>
          <router-link
            :to="{ name: 'products' }"
            active-class="active"
            class="nav-link"
          >
            Ürünler
          </router-link>
          <router-link
            :to="{ name: 'basket' }"
            active-class="active"
            class="nav-link px-4"
          >
            <div class="position-relative">
              <i class="bi bi-basket-fill"></i>
              <span
                class="position-absolute translate-middle badge rounded-pill bg-danger"
                style="top: -4px; left: 30px"
                v-if="_basket?.items?.length > 0"
              >
                {{ _basket?.items.length }}
              </span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      _basket: "Basket/_basket",
    }),
  },
};
</script>

<style lang="scss" scoped>
nav {
  padding: 0 40px;

  .container-fluid {
    a,
    button {
      padding: 20px 0;
      transition: all 0.3s ease-in-out;
    }

    a:hover,
    a.active {
      -webkit-box-shadow: inset 0px 5px 0px 0px rgb(37, 79, 26);
      -moz-box-shadow: inset 0px 5px 0px 0px rgb(37, 79, 26);
      box-shadow: inset 0px 5px 0px 0px rgb(37, 79, 26);
    }
  }
}
</style>
