import axios from "axios";

export default {
  namespaced: true,
  state: {
    basket: [],
  },
  getters: {
    _basket(state) {
      return state.basket;
    },
  },
  mutations: {
    setBasket(state, payload) {
      state.basket = payload;
    },
  },
  actions: {
    CREATE_BASKET(context) {
      return new Promise((resolve, reject) => {
        axios
          .post("/baskets/create")
          .then((res) => {
            context.commit("setBasket", res.data.basket);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setBasket", []);
            reject(err?.response?.data || err);
          });
      });
    },

    GET_BASKET(context) {
      return new Promise((resolve, reject) => {
        axios
          .get("/baskets/get?device_id=" + localStorage.getItem("device_id"))
          .then((res) => {
            context.commit("setBasket", res.data.basket);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setBasket", []);
            reject(err?.response?.data || err);
          });
      });
    },

    ADD_BASKET(context, item) {
      return new Promise((resolve, reject) => {
        axios
          .post("/baskets/add_item", {
            device_id: localStorage.getItem("device_id"),
            item,
          })
          .then((res) => {
            context.commit("setBasket", res.data.basket);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setBasket", []);
            reject(err?.response?.data || err);
          });
      });
    },

    REMOVE_BASKET(context, item) {
      return new Promise((resolve, reject) => {
        axios
          .post("/baskets/remove_item", {
            device_id: localStorage.getItem("device_id"),
            item,
          })
          .then((res) => {
            context.commit("setBasket", res.data.basket);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setBasket", []);
            reject(err?.response?.data || err);
          });
      });
    },

    UPDATE_BASKET(context, items) {
      return new Promise((resolve, reject) => {
        axios
          .post("/baskets/update_item", {
            device_id: localStorage.getItem("device_id"),
            items,
          })
          .then((res) => {
            context.commit("setBasket", res.data.basket);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setBasket", []);
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
