import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
// axios.defaults.baseURL = "http://localhost:3000/api";
axios.defaults.baseURL = "/api";

/* BOOTSTRAP */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

/* BOOTSTRAP ICONS */
import "bootstrap-icons/font/bootstrap-icons.css";

createApp(App).use(store).use(router).mount("#app");
