<template>
  <div>
    <AppTopHeader />
    <AppHeader />
    <router-view />
    <AppFooter />
    <transition mode="out-in" name="blur">
      <div
        class="position-fixed"
        v-if="_message?.text"
        style="top: 1rem; right: 1rem"
      >
        <div
          class="toast show"
          role="alert"
          aria-live="polite"
          aria-atomic="true"
          data-bs-delay="10000"
        >
          <div class="toast-header">
            <strong class="me-auto">{{ _message?.title }}</strong>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          <div class="toast-body">{{ _message?.text }}</div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import AppFooter from "./components/shared/AppFooter.vue";
import AppHeader from "./components/shared/AppHeader.vue";
import AppTopHeader from "./components/shared/AppTopHeader.vue";

export default {
  components: { AppHeader, AppTopHeader, AppFooter },
  computed: {
    ...mapGetters({
      _message: "Toast/_message",
    }),
  },
  beforeCreate() {
    this.$router.beforeEach((to, from, next) => {
      if (!localStorage.getItem("device_id")) {
        this.$store
          .dispatch("Basket/CREATE_BASKET")
          .then((res) => {
            localStorage.setItem("device_id", res.basket.device_id);
          })
          .catch(() => {});
      } else {
        this.$store.dispatch("Basket/GET_BASKET").then((res) => {
          if (res.basket.device_id != localStorage.getItem("device_id")) {
            localStorage.setItem("device_id", res.basket.device_id);
          }
        });
      }
      next();
    });
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: "Link Sans";
  font-weight: 400;
  font-style: normal;
  src: url("@/assets/linksans.woff2") format("woff2");
}
@font-face {
  font-family: "Link Sans";
  font-weight: 700;
  font-style: bold;
  src: url("@/assets/linksans.woff2") format("woff2");
}
@font-face {
  font-family: "Dancing";
  src: url("@/assets/dancing.woff2") format("woff2");
}

body {
  margin: 0;
  padding: 0;
  font-family: "Link Sans";
}

.blur-enter-from {
  opacity: 0;
  filter: blur(12px);
}
.blur-enter-to,
.blur-leave-from {
  opacity: 1;
  filter: blur(0) grayscale(0);
}
.blur-leave-to {
  opacity: 0;
  filter: blur(12px);
}
.blur-enter-active,
.blur-leave-active {
  transition: all 450ms ease-in-out;
}

.basket-enter-from {
  opacity: 0;
  filter: blur(12px);
}
.basket-enter-to,
.basket-leave-from {
  opacity: 1;
  filter: blur(0) grayscale(0);
}
.basket-leave-to {
  opacity: 0;
  filter: blur(12px);
}
.basket-enter-active,
.basket-leave-active {
  transition: all 450ms ease-in-out;
}
</style>
