import axios from "axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    GET_SETTINGS(context, part) {
      return new Promise((resolve, reject) => {
        axios
          .get("/settings", {
            params: { part },
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
