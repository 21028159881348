export default {
  namespaced: true,
  state: {
    message: {},
  },
  getters: {
    _message(state) {
      return state.message;
    },
  },
  mutations: {
    setMessage(state, payload) {
      state.message = payload;
      setTimeout(() => {
        state.message = {};
      }, 5000);
    },
  },
  actions: {},
};
