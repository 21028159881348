<template>
  <div class="content">
    <div class="row">
      <div class="col-md-6 d-flex flex-column justify-content-center gap-3">
        <hr class="d-block mb-3 w-25" />
        <!-- intro_1_head -->
        <h2>{{ settings.intro_1_head }}</h2>
        <p class="fs-5" v-if="settings.intro_1_subhead">
          {{ settings.intro_1_subhead }}
        </p>
        <p>
          {{ settings.intro_1_text }}
        </p>
        <router-link :to="{ name: 'products' }">SHOP NOW</router-link>
      </div>
      <div class="col-md-6">
        <img :src="settings.intro_1_image" class="img-fluid" alt="" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <img :src="settings.intro_2_image" class="img-fluid" alt="" />
      </div>
      <div class="col-md-6 d-flex flex-column justify-content-center gap-3">
        <hr class="d-block mb-3 w-25" />
        <h2>{{ settings.intro_2_head }}</h2>
        <p class="fs-5" v-if="settings.intro_2_subhead">
          {{ settings.intro_2_subhead }}
        </p>
        <p>
          {{ settings.intro_2_text }}
        </p>
        <router-link :to="{ name: 'products' }">EXPLORE NOW</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      settings: {
        intro_1_image: "",
        intro_1_head: "",
        intro_1_subhead: "",
        intro_1_text: "",
        intro_2_image: "",
        intro_2_head: "",
        intro_2_subhead: "",
        intro_2_text: "",
      },
    };
  },
  mounted() {
    this.$store
      .dispatch(
        "Settings/GET_SETTINGS",
        "intro_1_image,intro_1_head,intro_1_subhead,intro_1_text,intro_2_image,intro_2_head,intro_2_subhead,intro_2_text",
      )
      .then((res) => {
        this.settings = res.settings;
      })
      .catch(() => {});
  },
};
</script>
<style lang="scss" scoped>
.content {
  background: #d0d8cd;
  padding: 0 2rem;

  .col-md-6 {
    padding: 0;

    a {
      border-radius: 10px;
      font-family: "Link Sans", Sans-serif;
      font-weight: normal;
      background-color: rgb(37, 79, 26);
      text-decoration: none;

      width: max-content;
      line-height: 1;
      font-size: 15px;
      padding: 12px 24px;
      color: #fff;
      fill: #fff;
      text-align: center;
      transition: all 0.3s;
    }

    hr {
      height: 10px;
      background-color: rgb(37, 79, 26);
      opacity: 1;
    }

    h2 {
      font-weight: 600;
    }
  }

  .row:nth-child(1) .col-md-6:nth-child(1) {
    padding: 50px 75px;
  }

  .row:nth-child(2) .col-md-6:nth-child(2) {
    padding: 50px 75px;
  }
}
</style>
