<template>
  <section v-if="_products.length > 0">
    <h2>Diğer Ürünlerimiz #urunonerisi</h2>

    <div class="my-5">
      <swiper
        :slides-per-view="4"
        :modules="modules"
        navigation
        :space-between="100"
      >
        <swiper-slide v-for="product of _products" :key="product._id">
          <router-link
            :to="{ name: 'product', params: { slug: product.slug } }"
          >
            <img class="product_image" :src="product.images[0]" alt="" />
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import { mapGetters } from "vuex";

import "swiper/css";
import "swiper/css/navigation";

export default {
  name: "ProductsComp",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Navigation],
    };
  },
  computed: {
    ...mapGetters({
      _products: "Products/_products",
    }),
  },
  mounted() {
    this.$store.dispatch("Products/GET_PRODUCTS");
  },
};
</script>
<style lang="scss" scoped>
section {
  background-color: white;
  padding: 50px;
  h2 {
    font-family: "Dancing";
    color: #264f1a;
    text-align: center;
  }

  .product_image {
    width: 350px;
    height: 350px;
    border-radius: 10px;
    object-fit: cover;
  }
}
</style>

<style>
.swiper-button-prev,
.swiper-button-next {
  color: black;
}
</style>
