import { createStore } from "vuex";

import Basket from "./modules/basket";
import Products from "./modules/products";
import Orders from "./modules/orders";
import Toast from "./modules/toast";
import Settings from "./modules/settings";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    Basket,
    Products,
    Orders,
    Toast,
    Settings,
  },
});
