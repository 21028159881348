import axios from "axios";

export default {
  namespaced: true,
  state: {
    order_status: null,
  },
  getters: {
    _order_status(state) {
      return state.order_status;
    },
  },
  mutations: {
    setOrderStatus(state, payload) {
      state.order_status = payload;
    },
  },
  actions: {
    CREATE_ORDER(context, personel_information) {
      return new Promise((resolve, reject) => {
        axios
          .post("/orders/create", {
            personel_information,
            device_id: localStorage.getItem("device_id"),
          })
          .then((res) => {
            context.commit("setOrderStatus", res.data.order._id);
            resolve(res.data);
          })
          .catch((err) => {
            context.commit("setOrderStatus", false);
            reject(err?.response?.data || err);
          });
      });
    },
  },
};
