<template>
  <footer>
    <p>© 2024 Sepetledim | Tüm hakları saklıdır.</p>

    <small>
      Bu websitesi
      <a href="mailto:omerfarukbicer0446@gmail.com">Fribe Cloud Hizmetleri</a>
      tarafından inşa edilmiştir.
    </small>
  </footer>
</template>
<script>
export default {
  name: "AppFooter",
};
</script>
<style lang="scss" scoped>
footer {
  width: 100%;
  text-align: center;
  padding: 1rem;
  background-color: white;
  margin-bottom: 70px;

  p {
    font-weight: 400;
    border-bottom: 1px solid #dedede;
    padding-bottom: 12px;
    margin-bottom: 12px;
  }
}
</style>
