<template>
  <div class="top_header">FREE SHIPPING WORLDWIDE 🌍</div>
</template>
<script>
export default {
  name: "TopHeader",
};
</script>
<style lang="scss" scoped>
.top_header {
  text-align: center;
  width: 100%;
  background-color: rgb(37, 79, 26);
  color: white;
  font-weight: 300;
  padding: 10px 0;
}
</style>
